import { OvFormProvider, OvTabList, PageWrapper } from '@components'
import {
  CLIENT_DEFAULT_VALUES,
  CLIENT_DETAIL_TABS,
  LABELS,
  URL_PATHS,
} from '@constants'
import { useStore } from '@hooks/useStore'
import { Add } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, ButtonGroup, Tab } from '@mui/material'
import { IOVErrorResponseData, ISingleClient } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm, useWatch } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const ClientCreateView = observer(() => {
  const { client, shared, ui } = useStore()
  const [selectedTab, setSelectedTab] = useState(
    CLIENT_DETAIL_TABS.CREATE[0].value
  )

  const navigate = useNavigate()

  const handleTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: string
  ) => setSelectedTab(val)

  const form = useForm({
    defaultValues: CLIENT_DEFAULT_VALUES,
  })

  const clientName = useWatch({ name: 'name', control: form.control })

  const onSubmitCallBack = (data: FieldValues) => {
    client
      .create(data as ISingleClient)
      .then(res => handleSucces(res))
      .catch((e: IOVErrorResponseData) => {
        handleErrors(e.errors, e.message)
      })
  }

  const handleCancel = useCallback(async () => {
    if (form.formState.isDirty)
      await ui
        .confirm({
          title: 'Uw wijzigingen worden niet opgeslagen',
          content:
            // eslint-disable-next-line max-len
            'Als je op akkoord klikt, worden je aangebrachte wijzigingen niet opgeslagen. Is dit akkoord?',
          withCancel: true,
        })
        .then(() => {
          form.reset()
        })
        .catch(() => void 0)
  }, [form.formState.isDirty])

  const handleBackAction = async () => {
    await handleCancel()
    navigate(URL_PATHS.CLIENT_LIST)
  }

  const handleSucces = (data: ISingleClient) => {
    console.log('data: ', data)
    const route = generatePath(URL_PATHS.CLIENT_DETAIL, { id: data.id })
    toast('Klantgegevens opggeslagen', { type: 'success' })
    return navigate(route)
  }

  const handleErrors = (errors: Record<string, string[]>, message?: string) => {
    form.clearErrors()
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as keyof typeof CLIENT_DEFAULT_VALUES, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )

    if (message) toast(message, { type: 'error' })
  }

  const handleSubmit = () => {
    form.handleSubmit(onSubmitCallBack)()
  }

  const getPageTitle = useMemo(
    () => (clientName !== '' ? clientName : LABELS.CLIENT_CREATE),
    [clientName]
  )

  const renderClientTabs = useMemo(
    () => (
      <TabContext value={selectedTab}>
        <OvTabList onChange={handleTabChange}>
          {CLIENT_DETAIL_TABS.CREATE.map(singleTab => (
            <Tab
              key={singleTab.value}
              value={singleTab.value}
              label={singleTab.label}
            />
          ))}
        </OvTabList>
        <Box>
          {CLIENT_DETAIL_TABS.CREATE.map(singleTab => (
            <TabPanel
              key={singleTab.value}
              sx={{ padding: '24px 0' }}
              value={singleTab.value}>
              {singleTab.component}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    ),
    [selectedTab, client.current_client, client.single, client.loading]
  )

  useEffect(() => {
    shared.getCountries()
  }, [])

  return (
    <OvFormProvider
      form={form}
      onSubmit={() => handleSubmit}>
      <PageWrapper
        pageTitle={getPageTitle}
        backAction={handleBackAction}
        actions={
          <ButtonGroup>
            <Button
              endIcon={<Add />}
              variant="contained"
              onClick={handleSubmit}>
              Klant opslaan
            </Button>
          </ButtonGroup>
        }>
        {renderClientTabs}
      </PageWrapper>
    </OvFormProvider>
  )
})
