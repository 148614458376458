import { OvCard, OvForm, OvFormInput } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Stack } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ISingleClient } from '@typings'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'

export const ClientUpdateForm = observer(() => {
  const { shared } = useStore()
  const FormInput = OvFormInput<ISingleClient>
  const {
    reset,
    formState: { isDirty },
  } = useFormContext()

  return (
    <OvForm>
      <OvCard>
        <Stack
          direction="column"
          gap={2}>
          <Grid2
            container
            spacing={2}>
            <Grid2
              xs={12}
              sm={6}>
              <Stack
                direction="column"
                gap={2}>
                <FormInput
                  name="name"
                  label={LABELS.CLIENT_NAME}
                />
                <FormInput
                  name="street"
                  label={LABELS.STREET}
                />
                <FormInput
                  name="number"
                  label={LABELS.HOUSE_NUMBER}
                />
                <FormInput
                  name="postal_code"
                  label={LABELS.POSTAL_CODE}
                />
                <FormInput
                  name="city"
                  label={LABELS.CITY}
                />
                <FormInput
                  type="select"
                  name="country_id"
                  label={'Land'}
                  options={shared.countries}
                  labelKey="name"
                  valueKey="id"
                />
              </Stack>
            </Grid2>
            <Grid2
              xs={12}
              sm={6}>
              <Stack
                direction="column"
                gap={2}>
                <FormInput
                  label={LABELS.COC}
                  name="coc"
                />
                <FormInput
                  label={LABELS.TAX_NUMBER}
                  name="tax"
                />
              </Stack>
            </Grid2>
          </Grid2>
        </Stack>
      </OvCard>
    </OvForm>
  )
})
