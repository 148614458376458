import { OvFormInputReadOnly, OvPriceInput } from '@components'
import { KEYS, LABELS } from '@constants'
import { formatValueToCurrency } from '@helpers/formatCentToAmount.helper'
import { useStore } from '@hooks/useStore'
import { Add, Edit } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ISingleCountry, ISingleDocument, IVatCode } from '@typings'
import { ClientCreateView } from '@views'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { OvFormInput } from 'src/components/core/ov-form-input/ov-form-input'
import { OvFormContext } from 'src/components/core/ov-form-provider/ov-form-provider'
import { OvForm } from 'src/components/core/ov-form/ov-form'

interface ITaskTableColumns {
  label: string
  key: string
  align: 'left' | 'right'
}

interface ILineTotal {
  index: number
  total: number
  totalVat: number
  totalExVat: number
}

export const UpdateInvoiceForm = observer(() => {
  const [lineTotals, setLinesTotal] = useState<ILineTotal[]>([])
  const [expanded, setExpanded] = useState<string | false>(KEYS.GENERAL)
  const form = useFormContext()
  const formContext = useContext(OvFormContext)
  const { client, company, invoice, shared, ui } = useStore()
  const FormInput = OvFormInput<ISingleDocument>
  const { fields, update, append, remove } = useFieldArray({
    control: form.control,
    name: 'lines',
  })
  const {
    formState: { defaultValues },
    setValue,
  } = useFormContext<ISingleDocument>()

  const length = useWatch({
    name: 'invoice_expires_after',
    control: form.control,
    defaultValue: 30,
  })
  const start = useWatch({
    name: 'issued_at',
    control: form.control,
    defaultValue: new Date(),
  })

  const getDefaultExpiredValue = useMemo(() => {
    let date = dayjs().add(30, 'days').toDate()
    if (start && length) date = dayjs(start).add(length, 'days').toDate()
    setValue('expires_at', date)
    return date
  }, [length, start])

  const getCurrentCountryName = useCallback(
    (id: ISingleCountry['id'] | undefined) => {
      const countryName =
        shared.countries && shared.countries.find(el => el.id === id)?.name

      if (!countryName) return ''
      return countryName
    },
    [shared.countries]
  )

  const updateTotals = useCallback(
    (newValues: ILineTotal) => {
      const newArray = [...lineTotals]
      const index = newArray.findIndex(({ index }) => index === newValues.index)
      const currentValues = newArray[index]

      const isSame = JSON.stringify(currentValues) === JSON.stringify(newValues)

      if (!isSame) {
        if (index > -1) {
          newArray[index] = newValues
        } else {
          newArray.push(newValues)
        }
        setLinesTotal(newArray)
        console.groupEnd()
      }
    },
    [lineTotals]
  )

  const lines = useWatch({ name: 'lines', control: form.control })

  const handleAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const calculatedTotals = useMemo(() => {
    const calculateTotal = (key: keyof ILineTotal) =>
      lineTotals.reduce((bulk, curr) => bulk + curr[key], 0)
    const vat = calculateTotal('totalVat')
    const totalExVat = calculateTotal('totalExVat')
    const total = calculateTotal('total')
    return { vat, totalExVat, total }
  }, [lines])

  const taskTableColumns: ITaskTableColumns[] = [
    {
      label: 'Taak',
      key: 'label',
      align: 'left',
    },
    {
      label: 'Prijs',
      key: 'price',
      align: 'right',
    },
    {
      label: 'Aantal',
      key: 'quantity',
      align: 'right',
    },
    {
      label: 'Subtotaal',
      key: 'subtotal',
      align: 'right',
    },
    {
      label: 'BTW percentage',
      key: 'percentage',
      align: 'right',
    },
    {
      label: 'Totaal BTW',
      key: 'totalVat',
      align: 'right',
    },
    {
      label: 'Totaal',
      key: 'total',
      align: 'right',
    },
  ]

  const handleAddRow = () => {
    append({ label: '', quantity: 1, price: 0, vat_code: { percentage: 0 } })
  }

  const handleEditClient = async () => {
    return ui
      .showModal({
        title: 'Nieuwe klant aanmaken',
        body: () => <ClientCreateView />,
      })
      .then(res => console.log('res: ', res))
      .catch(e => console.log('e: ', e))
  }

  const renderTableData = useMemo(() => {
    return (
      <TableContainer sx={{ height: 'auto' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {taskTableColumns.map(singleColumn => (
                <TableCell
                  key={singleColumn.key}
                  align={singleColumn.align}>
                  {singleColumn.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map(
              (
                {
                  id,
                  quantity,
                  vat_code,
                  vat_code_id,
                  price,
                  label,
                  ...rest
                }: any,
                index
              ) => {
                const currentValues = useWatch({
                  name: `lines.${index}`,
                })

                const currentVatPercentage = useMemo(
                  () =>
                    shared.current_vat_codes.find(
                      ({ id }) => id === currentValues.vat_code_id
                    )?.percentage,
                  [shared.current_vat_codes, currentValues.vat_code_id]
                )

                const totalExVat = useCallback(
                  () => currentValues.quantity * currentValues.price,
                  [currentValues.quantity, currentValues.price]
                )

                const totalVat = useCallback(
                  () =>
                    currentVatPercentage
                      ? (totalExVat() / 100) * currentVatPercentage
                      : 0,
                  [currentVatPercentage, totalExVat]
                )
                const total = useCallback(() => {
                  const total = totalExVat() + totalVat()
                  return total
                }, [totalExVat, totalVat])

                updateTotals({
                  index,
                  totalExVat: totalExVat(),
                  totalVat: totalVat(),
                  total: total(),
                })

                return (
                  <TableRow key={`${id}-${quantity}-${index}`}>
                    <TableCell>
                      {formContext.readOnly ? (
                        label
                      ) : (
                        <TextField
                          disabled={formContext.readOnly}
                          size="small"
                          {...form.register(`lines.${index}.label`)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formContext.readOnly ? (
                        formatValueToCurrency(price)
                      ) : (
                        <Controller
                          name={`lines.${index}.price`}
                          control={form.control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                            formState,
                          }) => (
                            <OvPriceInput
                              variant="outlined"
                              disabled={formContext.readOnly}
                              error={!!error}
                              helperText={error && error.message}
                              size="small"
                              onBlur={onBlur}
                              onChange={(value: any) => {
                                onChange(value * 100)
                              }}
                              inputRef={ref}
                              value={
                                formState.disabled
                                  ? formatValueToCurrency(value)
                                  : value / 100
                              }
                            />
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ flex: 0.5 }}
                      align="right">
                      {formContext.readOnly ? (
                        quantity
                      ) : (
                        <TextField
                          disabled={formContext.readOnly}
                          size="small"
                          {...form.register(`lines.${index}.quantity`, {
                            valueAsNumber: true,
                          })}
                          inputProps={{ type: 'number', min: 0 }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      key={`${quantity}-1-${price}-${vat_code_id}`}
                      align="right">
                      {formatValueToCurrency(totalExVat())}
                    </TableCell>
                    <TableCell
                      key={`${quantity}-2-${price}-${vat_code_id}`}
                      align="right">
                      {formContext.readOnly ? (
                        `${vat_code?.percentage}%`
                      ) : (
                        <OvFormInput
                          size="small"
                          label=""
                          name={`lines.${index}.vat_code_id`}
                          type="select"
                          options={shared.current_vat_codes as IVatCode[]}
                          valueKey="id"
                          labelKey="percentage"
                          output="value"
                          renderOption={value =>
                            `${value.percentage}% (${value.name})`
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      key={`${quantity}-${price}-3-${vat_code_id}`}>{`${
                      formatValueToCurrency(totalVat()) ?? '-'
                    }`}</TableCell>
                    <TableCell
                      align="right"
                      key={`${quantity}-${price}-4-${vat_code_id}`}>
                      {formatValueToCurrency(total())}
                    </TableCell>
                  </TableRow>
                )
              }
            )}
          </TableBody>
        </Table>
        {!formContext.readOnly && (
          <Box
            display="flex"
            mt={2}>
            <Button
              variant="text"
              onClick={handleAddRow}
              endIcon={<Add />}>
              Regel toevoegen
            </Button>
          </Box>
        )}
      </TableContainer>
    )
  }, [fields, form.control, formContext, shared.current_vat_codes, lines])

  return (
    <>
      <OvForm>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {formContext.readOnly ? (
            <Card sx={{ p: 2 }}>
              <Stack
                spacing={3}
                direction="column">
                <Box>
                  <Typography
                    variant="h4"
                    mb={2}>
                    Algemeen
                  </Typography>
                  <Grid2
                    container
                    spacing={2}>
                    <Grid2
                      xs={12}
                      md={6}>
                      <Stack spacing={2}>
                        <FormInput
                          name="client_id"
                          type="select"
                          label="Klant"
                          options={client.list}
                          valueKey="id"
                          labelKey="name"
                          output="value"
                        />
                        {formContext.readOnly && (
                          <OvFormInputReadOnly label="Adress">
                            <List
                              dense
                              disablePadding>
                              <ListItemText
                                primary={defaultValues?.client?.street}
                              />
                              <ListItemText
                                primary={defaultValues?.client?.postal_code}
                              />
                              <ListItemText
                                primary={defaultValues?.client?.city}
                              />
                              <ListItemText
                                primary={getCurrentCountryName(
                                  defaultValues?.client?.country_id
                                )}
                              />
                            </List>
                          </OvFormInputReadOnly>
                        )}
                      </Stack>
                    </Grid2>
                    <Grid2
                      xs={12}
                      md={6}>
                      <Stack spacing={2}>
                        <FormInput
                          name="issued_at"
                          type="date"
                          label="Factuur datum"
                          defaultValue={new Date()}
                        />
                        <FormInput
                          name="number"
                          label="Factuur nummer"
                        />
                      </Stack>
                    </Grid2>
                  </Grid2>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    mb={2}>
                    Regels
                  </Typography>
                  {renderTableData}
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    mb={2}>
                    Voorwaarden
                  </Typography>
                  <Grid2
                    container
                    spacing={2}>
                    <Grid2
                      xs={12}
                      md={6}>
                      <FormInput
                        type="date"
                        name="expires_at"
                        label={LABELS.INVOICE_EXPIRES_AFTER}
                        defaultValue={getDefaultExpiredValue}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </Stack>
            </Card>
          ) : (
            <>
              <Accordion
                defaultExpanded
                expanded={expanded === KEYS.GENERAL}
                onChange={handleAccordion(KEYS.GENERAL)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}>
                  <Typography variant="h4">Algemeen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2
                    container
                    spacing={2}>
                    <Grid2
                      xs={12}
                      md={6}>
                      <FormInput
                        name="issued_at"
                        type="date"
                        label="Factuur datum"
                        disablePast
                      />
                    </Grid2>
                    <Grid2
                      xs={12}
                      md={6}>
                      <FormInput
                        name="number"
                        label="Factuur nummer"
                      />
                    </Grid2>
                    <Grid2 xs={12}>
                      <Stack spacing={2}>
                        <Stack
                          spacing={1}
                          direction="column">
                          <FormInput
                            name="client_id"
                            type="select"
                            label="Klant"
                            options={client.list}
                            valueKey="id"
                            labelKey="name"
                            output="value"
                          />
                          {!formContext.readOnly && (
                            <Stack
                              direction="row"
                              spacing={2}>
                              <Button
                                size="small"
                                variant="text"
                                endIcon={<Edit />}>
                                Klant bewerken
                              </Button>
                              <Button
                                size="small"
                                variant="text"
                                onClick={handleEditClient}
                                endIcon={<Add />}>
                                Nieuwe klant aanmaken
                              </Button>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Grid2>
                  </Grid2>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === KEYS.LINES}
                onChange={handleAccordion(KEYS.LINES)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}>
                  <Typography variant="h4">Regels</Typography>
                </AccordionSummary>
                <AccordionDetails>{renderTableData}</AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === KEYS.TERMS}
                onChange={handleAccordion(KEYS.TERMS)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}>
                  <Typography variant="h4">Voorwaarden</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2
                    container
                    spacing={2}>
                    <Grid2
                      xs={12}
                      md={6}>
                      <FormInput
                        type="date"
                        name="expires_at"
                        disablePast
                        label={LABELS.INVOICE_EXPIRES_AFTER}
                        value={getDefaultExpiredValue}
                      />
                    </Grid2>
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </OvForm>
      <Card
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          mt: 2,
        }}>
        <CardContent>
          <Grid2
            container
            spacing={2}>
            <Grid2 xs={4}>
              <Typography>Subtotaal</Typography>
              <Typography
                color="primary"
                fontWeight="bold">
                {formatValueToCurrency(
                  formContext.readOnly
                    ? invoice.single.subtotal
                    : calculatedTotals.totalExVat
                )}
              </Typography>
            </Grid2>
            <Grid2 xs={4}>
              <Typography>BTW</Typography>
              <Typography
                color="primary"
                fontWeight="bold">
                {formatValueToCurrency(
                  formContext.readOnly
                    ? invoice.single.vat
                    : calculatedTotals.vat
                )}
              </Typography>
            </Grid2>
            <Grid2 xs={4}>
              <Typography>Totaal</Typography>
              <Typography
                color="primary"
                fontWeight="bold">
                {formatValueToCurrency(
                  formContext.readOnly
                    ? invoice.single.total
                    : calculatedTotals.total
                )}
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </>
  )
})
