import * as Yup from 'yup'
import { FORM_LABELS, LABELS } from '@constants'

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(FORM_LABELS.REQUIRED(LABELS.NAME)),
    email: Yup.string()
      .email(FORM_LABELS.INVALID(LABELS.EMAIL))
      .required(FORM_LABELS.REQUIRED(LABELS.EMAIL)),
    password: Yup.string().required(FORM_LABELS.REQUIRED(LABELS.PASSWORD)),
    password_confirmation: Yup.string()
      .required(LABELS.CONFIRM_PASSWORD_REQUIRED)
      .oneOf([Yup.ref('password')], LABELS.CONFIRM_PASSWORD_DOES_NOT_MATCH),
  })
