import { TabList, TabListProps } from '@mui/lab'

export const OvTabList = ({ children, ...rest }: any) => {
  return (
    <TabList
      allowScrollButtonsMobile
      variant="scrollable"
      {...rest}>
      {children}
    </TabList>
  )
}
