import { formatValueToCurrency } from '@helpers/formatCentToAmount.helper'
import { Box, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { ISingleDocument } from '@typings'
import dayjs from 'dayjs'

export interface IMobileDocumentRow extends ISingleDocument {
  onRowClick?: (id: ISingleDocument['id']) => void
}

export const MobileDocumentRow = ({
  number,
  issued_at,
  client,
  total = 0,
  id,
  onRowClick,
}: IMobileDocumentRow) => {
  const handleRowClick = (id: ISingleDocument['id']) => {
    if (onRowClick) onRowClick(id)
  }

  return (
    <Box
      sx={({ palette }) => ({
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: palette.divider,
        boxSizing: 'border-box',
        cursor: 'pointer',
        p: 2,
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      })}
      onClick={() => handleRowClick({ id } as any)}>
      <Grid2
        container
        spacing={2}>
        <Grid2 xs={8}>
          <Stack>
            <Typography variant="subtitle1">{client.name}</Typography>
            <Typography variant="h5">{formatValueToCurrency(total)}</Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={4}>
          <Stack>
            <Typography variant="caption">
              {issued_at && dayjs(issued_at).format('DD-MM-YYYY')}
            </Typography>
            <Typography
              variant="caption"
              color="GrayText">
              {number}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  )
}
