import { LABELS } from '@constants'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useSetPageTitle = (title: string | null) => {
  useEffect(() => {
    document.title = title
      ? `${LABELS.MY_OUTVOICE} - ${title}`
      : LABELS.MY_OUTVOICE
  }, [title])
}
