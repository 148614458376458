import { OvFormProvider, PageWrapper } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/useStore'
import { IUser } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { ProfileForm } from 'src/partials/forms/profile/profile-form'

export const ProfileView = observer(() => {
  const { user, ui } = useStore()

  const form = useForm({ defaultValues: user.current_user })

  const handleSuccess = (data: IUser) => {
    form.reset(data)
    ui.handleToaster('Wijzigingen opgeslagen', { type: 'success' })
  }

  const onSubmitCallBack = async (data: FieldValues) => {
    await user.update(data as IUser).then(res => handleSuccess(res))
  }

  const handleSubmit = () => {
    form.handleSubmit(onSubmitCallBack)
  }

  // useEffect(() => {
  //   user.whoAmI()
  // }, [])

  return (
    <PageWrapper
      pageTitle={LABELS.MY_PROFILE}
      loading={user.loading}>
      <OvFormProvider
        form={form}
        onSubmit={handleSubmit}>
        <ProfileForm />
      </OvFormProvider>
    </PageWrapper>
  )
})
