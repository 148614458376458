import { LABELS, URL_PATHS } from '@constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStore } from '@hooks/useStore'
import { Error } from '@mui/icons-material'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormHelperText,
} from '@mui/material'
import { IOVErrorResponseData, ISingleUser, IUserRegister } from '@typings'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { OvFormInput } from 'src/components/core/ov-form-input/ov-form-input'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'
import { OvForm } from 'src/components/core/ov-form/ov-form'
import styles from './register.form.module.scss'
import { validationSchema } from './validation/register.form.validation'

type IRegisterForm = ISingleUser & { confirm_password: string }

export const RegisterForm = observer(() => {
  const { auth } = useStore()
  const [formError, setFormError] = useState<string | null>(null)
  const navigate = useNavigate()

  const form = useForm<IUserRegister>({
    resolver: yupResolver(validationSchema()),
    defaultValues: {
      name: undefined,
      email: '',
      password: '',
      password_confirmation: '',
    },
    mode: 'all',
  })

  const { formState } = form

  const handleRegister: SubmitHandler<IUserRegister> = async data => {
    setFormError(null)
    await auth
      .register(data)
      .then(() => navigate(URL_PATHS.LOGIN))
      .catch((errors: IOVErrorResponseData) => handleFormErrors(errors))
  }

  const handleFormErrors = (data: IOVErrorResponseData) => {
    const { message, errors } = data

    if (message) setFormError(message)

    if (errors) {
      const errorKeys = Object.keys(errors)

      errorKeys.map(singleKey =>
        form.setError(singleKey as any, {
          type: 'custom',
          message: errors[singleKey][0],
        })
      )
    }
  }

  const sumitForm = () => {
    form.handleSubmit(handleRegister)()
  }

  return (
    <OvFormProvider
      form={form}
      onSubmit={sumitForm}
      loading={auth.loading}>
      <OvForm>
        <Card
          className={styles['register-card']}
          elevation={4}>
          <CardHeader title={LABELS.REGISTER_FORM_TITLE} />
          <CardContent className={styles['register-card-content']}>
            <OvFormInput
              name="name"
              inputProps={{ type: 'text' }}
              label={LABELS.NAME}
              required
            />
            <OvFormInput
              name="email"
              inputProps={{ type: 'email' }}
              label={LABELS.EMAIL}
              required
            />
            <OvFormInput
              name="password"
              inputProps={{ type: 'password' }}
              label={LABELS.PASSWORD}
              required
            />
            <OvFormInput
              name="password_confirmation"
              inputProps={{ type: 'password' }}
              label={LABELS.PASSWORD_AGAIN}
              required
            />
            <Collapse in={!!formError}>
              <Alert
                color="error"
                icon={<Error />}>
                <FormHelperText error>{formError}</FormHelperText>
              </Alert>
            </Collapse>
          </CardContent>
          <CardActions>
            <Button
              disabled={
                formState.isSubmitting || auth.loading || !formState.isValid
              }
              variant="contained"
              type="submit"
              sx={{ flex: '1 0 auto' }}>
              {LABELS.REGISTER}
            </Button>
          </CardActions>
          <Divider>Al een account?</Divider>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Button onClick={() => navigate(URL_PATHS.LOGIN)}>
              {LABELS.LOGIN}
            </Button>
          </CardActions>
        </Card>
      </OvForm>
    </OvFormProvider>
  )
})
