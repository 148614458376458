import { PageWrapper } from '@components'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RegisterForm } from '../../partials/index.partials'
import styles from './register.module.scss'
import { LABELS } from '@constants'
import { useSetPageTitle } from '@hooks/useSetPageTitle'

export const SignupView = observer((): JSX.Element => {
  useSetPageTitle(LABELS.REGISTER)
  return (
    <PageWrapper background="secondary-gradient">
      <div className={styles['register-wrapper']}>
        <div className={styles['register']}>
          <Typography
            variant="h1"
            color="white">
            Outvoice
          </Typography>
          <Typography
            variant="h1"
            color="white"></Typography>
          <RegisterForm />
        </div>
      </div>
    </PageWrapper>
  )
})
