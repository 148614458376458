import { STORES, STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import {
  ICompanyInvoice,
  ICompanyInvoiceClient,
  ICompanyInvoiceTask,
  ISingleDocument,
  ISingleDocumentLine,
} from '@typings'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { FieldValues } from 'react-hook-form'
import { DatagridStore } from './datagrid.stores'

const defaultValues: Record<string, any> = {
  list: [],
  single: {} as ISingleDocument,
}
export class InvoiceStore {
  [key: string]: any
  loading: boolean
  list: any[]
  data_grid: DatagridStore
  single: ISingleDocument
  store: Store

  constructor(store: Store) {
    this.store = store
    this.loading = true
    this.data_grid = new DatagridStore(this.store, STORES.INVOICE as string, {
      type: 'invoice',
    })
    this.list = defaultValues.list
    this.single = defaultValues.single
    makeAutoObservable(this)
  }

  @action
  getList = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const params = {
        type: 'invoice',
      }
      const { data, meta } = await this.store.api.documents.getAll({ params })
      this.set(STORE_KEYS.LIST, data)
    } catch (e) {
      console.log(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getSingle = async (id: ISingleDocument['id']) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.documents.getSingle(id)
      this.set(STORE_KEYS.SINGLE, result)
      return Promise.resolve(result)
    } catch (e) {
      console.error(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  update = async (data: FieldValues) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const res = await this.store.api.documents.update(
        this.single.id,
        data as ISingleDocument
      )
      this.set(STORE_KEYS.SINGLE, res)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  create = async (data: FieldValues) => {
    this.set(STORE_KEYS.LOADING, true)

    try {
      if (!this.store.company.current_company_id) {
        await this.store.company.getMyCompany()
      }
      const _data: Partial<ISingleDocument> = {
        ...data,
        type: 'invoice',
        company_id: this.store.company.current_company_id,
      }
      const res = await this.store.api.documents.create(_data)
      this.set(STORE_KEYS.SINGLE, res)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  reset = (withLoading?: boolean) => {
    runInAction(() =>
      Object.keys(defaultValues).map(key => {
        this.set(key, defaultValues[key])
      })
    )
    if (withLoading) this.set(STORE_KEYS.LOADING, false)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
