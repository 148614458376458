import {
  autoLoad,
  clearAllStorage,
  removeFromStorage,
  saveToStorage,
} from '@helpers/storage.helpers'
import { Store } from '@stores'
import { IOVErrorResponse, IUserLoginCreds, IUserRegister } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { EVENT_KEYS, STORE_KEYS } from 'src/constants/keys.contants'
import { IAuthUser } from 'src/typings/auth.typings'

export class AuthStore {
  [key: string]: any
  store: Store
  loading: boolean
  user: Omit<IAuthUser, 'access_token'>
  access_token: string | null
  refresh_token: string | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.sessionToken = null
    this.user = {} as Omit<IAuthUser, 'access_token'>
    this.userRole = null
    this.loading = false
    this.access_token = null
    this.refresh_token = null
    autoLoad(this, STORE_KEYS.ACCESS_TOKEN)
  }

  @computed
  get is_authenticated(): boolean {
    return this.access_token !== null
  }

  @action
  register = async (data: IUserRegister) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const _data = await this.store.api.auth.register(data)
      console.log('test123: ', _data)
      toast('Je bent succesvol geregisteerd.', { type: 'success' })
      return Promise.resolve(_data)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  login = async (data: IUserLoginCreds, callback?: () => void) => {
    this.set(STORE_KEYS.LOADING, true)
    const { email, password } = data
    try {
      const { access_token, ...rest } = await this.store.api.auth.login({
        email,
        password,
      })
      removeFromStorage(EVENT_KEYS.UNAUTH)

      this.set(STORE_KEYS.ACCESS_TOKEN, access_token, true)
      this.store.user.set(STORE_KEYS.USER, rest, true)
      // this.set(STORE_KEYS.REFRESH_TOKEN, refresh_token, true)
      if (callback) callback()
      return Promise.resolve()
    } catch (e: any & IOVErrorResponse) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  refreshToken = async (newToken: string) => {
    this.set(STORE_KEYS.ACCESS_TOKEN, newToken, true)
  }

  @action
  logout = async (fromEvent?: boolean) => {
    this.clearStorage()
    this.set(STORE_KEYS.REFRESH_TOKEN, null)
    this.set(STORE_KEYS.ACCESS_TOKEN, null)
    if (fromEvent) {
      saveToStorage(EVENT_KEYS.UNAUTH, true)
      this.store.ui.handleErrorToast(
        'Je bent uitgelogd omdat je sessie is verlopen. Log opnieuw in.'
      )
    }
  }

  @action
  clearStorage = () => {
    clearAllStorage()
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
