import { Box, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { ISingleClient, ISingleDocument } from '@typings'

export interface IMobileClientRow extends ISingleClient {
  onRowClick?: (id: ISingleDocument['id']) => void
}

export const MobileClientRow = ({
  name,
  city,
  coc,
  id,
  onRowClick,
}: IMobileClientRow) => {
  const handleRowClick = (id: ISingleClient['id']) => {
    if (onRowClick) onRowClick(id)
  }

  return (
    <Box
      sx={({ palette }) => ({
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: palette.divider,
        boxSizing: 'border-box',
        cursor: 'pointer',
        p: 2,
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      })}
      onClick={() => handleRowClick({ id } as any)}>
      <Grid2
        container
        spacing={2}>
        <Grid2 xs={8}>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="caption">{city}</Typography>
        </Grid2>
        <Grid2 xs={4}>
          <Stack>
            <Typography
              variant="caption"
              color="GrayText">
              {coc}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  )
}
