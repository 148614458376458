import { PageWrapper } from '@components'
import { LABELS, URL_PATHS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { useStore } from '@hooks/useStore'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'
import { LoginForm } from 'src/partials/index.partials'
import styles from './login.module.scss'
import { useSetPageTitle } from '@hooks/useSetPageTitle'

export const LoginView = observer(() => {
  const { auth } = useStore()
  const { state } = useLocation()

  if (auth.is_authenticated)
    return (
      <Navigate to={state?.intended ? state.intended : URL_PATHS.DASHBOARD} />
    )

  if (state?.intended) {
    saveToStorage('intended', state.intended)
  }

  useSetPageTitle(LABELS.LOGIN)

  return (
    <PageWrapper background="primary-gradient">
      <div className={styles['login-wrapper']}>
        <div className={styles['login']}>
          <Typography
            variant="h1"
            color="white">
            Outvoice
          </Typography>
          <LoginForm />
        </div>
      </div>
    </PageWrapper>
  )
})
