import { URL_PATHS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { Close, GroupAdd, ReceiptLong, TableView } from '@mui/icons-material'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const OutvoiceQuidAddMenu = observer(() => {
  const { auth } = useStore()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  if (!auth.is_authenticated || isMobile) return null

  const MENU_ITEMS = [
    {
      key: 1,
      label: 'Maak factuur',
      icon: <ReceiptLong />,
      action: () => navigate(URL_PATHS.INVOICE_CREATE),
    },
    {
      key: 2,
      label: 'Maak offerte',
      icon: <TableView />,
      action: () => navigate(URL_PATHS.QUOTE_CREATE),
    },
    {
      key: 3,
      label: 'Voeg klant toe',
      icon: <GroupAdd />,
      action: () => navigate(URL_PATHS.CLIENT_CREATE),
    },
  ]

  return (
    <SpeedDial
      ariaLabel="Snelmenu"
      sx={{ position: 'fixed', bottom: 24, right: 24 }}
      icon={<SpeedDialIcon />}
      openIcon={<Close />}>
      {MENU_ITEMS.map(singleAction => (
        <SpeedDialAction
          tooltipTitle={singleAction.label}
          tooltipPlacement="left"
          key={singleAction.key}
          onClick={singleAction.action}
          icon={singleAction.icon}
        />
      ))}
    </SpeedDial>
  )
})
