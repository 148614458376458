import { OvFormInput, OvFormProvider, PageWrapper } from '@components'
import { LABELS, URL_PATHS } from '@constants'
import { formatValueToCurrency } from '@helpers/formatCentToAmount.helper'
import { renderClientCell } from '@helpers/formatColumnData.helper'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { GridCell, GridRow, GridRowParams } from '@mui/x-data-grid'
import { ISingleDocument } from '@typings'
import { spawn } from 'child_process'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { OvDataGrid } from 'src/components/core/ov-data-grid/ov-data-grid'
import { MobileDocumentRow } from 'src/partials/index.partials'

export const InvoiceList = observer(() => {
  const { invoice } = useStore()
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const handleRowClick = (params: GridRowParams) => {
    if (!params.id) return
    const path = generatePath(URL_PATHS.INVOICE_DETAIL, { id: params.id })
    return navigate(path)
  }

  const handleNewClick = async () => {
    navigate(URL_PATHS.INVOICE_CREATE)
  }

  const CustomRow = useCallback(
    (props: any) =>
      isMobile ? (
        <MobileDocumentRow
          {...props.row}
          onRowClick={handleRowClick}
        />
      ) : (
        <GridRow {...props} />
      ),
    [isMobile]
  )

  return (
    <PageWrapper
      pageTitle={LABELS.INVOICES}
      actions={
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={handleNewClick}>
          Nieuw
        </Button>
      }>
      <OvDataGrid
        slots={{
          row: CustomRow,
        }}
        withToolBar
        onRowClick={handleRowClick}
        store={invoice.data_grid}
        responsiveColumns
      />
    </PageWrapper>
  )
})
