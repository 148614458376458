import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { ISingleClient, ISingleClientContact } from '@typings'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { DatagridStore } from './datagrid.stores'

const defaultValues: Record<string, any> = {
  loading: true,
  list: [],
  single: {},
  clientContacts: [],
  clientContact: {},
}
export class ClientStore {
  [key: string]: any
  loading: boolean
  list: ISingleClient[]
  data_grid: DatagridStore
  single: ISingleClient
  clientContacts: ISingleClientContact[]
  clientContact: ISingleClientContact

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = defaultValues.loading
    this.list = defaultValues.list
    this.data_grid = new DatagridStore(this.store, 'client')
    this.single = defaultValues.single
    this.clientContacts = defaultValues.clientContacts
    this.clientContact = defaultValues.clientContact
  }

  @computed
  get has_clients(): boolean {
    return this.list.length > 0
  }

  @computed
  get current_client(): ISingleClient {
    return this.single
  }

  @computed
  get current_client_contacts(): ISingleClientContact[] {
    return this.clientContacts
  }

  @action
  create = async (data: ISingleClient) => {
    this.set(STORE_KEYS.LOADING, true)
    if (!this.store.company.current_company_id) {
      await this.store.company.getMyCompany()
    }

    const _data = {
      ...data,
      company_id: this.store.company.current_company_id,
    }

    try {
      const result = await this.store.api.client.create(_data)
      this.set(STORE_KEYS.SINGLE, result)
      return Promise.resolve(result)
    } catch (e: any) {
      console.log('e: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  findClientObj = (id: ISingleClient['id']) => {
    const _client = this.list.find(item => item.id === id)
    return _client
  }

  @action
  getClientContacts = async (clientId?: ISingleClient['id']) => {
    const id = clientId ?? this.current_client.id

    if (!id) return
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.client.getContacts(id)
      this.set(STORE_KEYS.CLIENT_CONTACTS, result)
      return Promise.resolve(result)
    } catch (e: any) {
      return Promise.reject(e?.response?.data)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getSingleClientContact = async (clientId: ISingleClientContact['id']) => {
    this.set(STORE_KEYS.LOADING, true)
    if (this.clientContact.id) {
      this.set(STORE_KEYS.CLIENT_CONTACT, {})
    }
    try {
      const result = await this.store.api.client.getContact(clientId)
      this.set(STORE_KEYS.CLIENT_CONTACT, result)
      return Promise.resolve(result)
    } catch (e: any) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  updateContact = async (data: ISingleClientContact) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      await this.store.api.client.updateContact(data)
      await this.getClientContacts()
      return Promise.resolve()
    } catch (e: any) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  addContact = async (data: ISingleClientContact) => {
    this.set(STORE_KEYS.LOADING, true)
    const _data = { ...data, client_id: this.current_client.id }
    try {
      await this.store.api.client.addContact(_data)
      await this.getClientContacts()
      return Promise.resolve()
    } catch (e: any) {
      return Promise.reject(e?.response?.data)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  removeContact = async (id: ISingleClientContact['id']) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      await this.store.api.client.removeContact(id)
      await this.getClientContacts()
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  update = async (data: ISingleClient) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.client.update(
        this.current_client.id,
        { ...data, number: 1 }
      )
      this.set(STORE_KEYS.SINGLE, result)
      return Promise.resolve(result)
    } catch (e: any) {
      return Promise.reject(e?.response?.data)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  remove = async () => {
    await this.store.ui.confirm({
      title: 'Klant verwijderen?',
      content:
        'Je staat op het punt deze klant te verwijderen. Weet je het zeker?',
      withCancel: true,
    })
    this.set(STORE_KEYS.LOADING, true)
    try {
      console.log('remove vlient')
    } catch (e) {
      console.log(e)
    }
  }

  @action
  createClient = async (data: any) => {
    this.set(STORE_KEYS.LOADING, true)
    const newClient = {
      name: data.handelsnaam,
      kvkNumber: data.kvkNummer,
    }
    try {
      console.log('data: ', newClient)
      await this.store.api.company.createClient(newClient)
      await this.data_grid.getData()
      return
    } catch (e) {
      console.log('error: ', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getSingle = async (id: ISingleClient['id']) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const res = await this.store.api.client.getSingle(id)
      this.set(STORE_KEYS.SINGLE, { ...res, number: undefined })
      return Promise.resolve({ ...res, number: undefined })
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getAll = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const res = await this.store.api.client.getAll()
      this.set(STORE_KEYS.LIST, res)
    } catch (e) {
      console.log('error: ', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  reset = (withLoading?: boolean) => {
    runInAction(() =>
      Object.keys(defaultValues).map(key => {
        this.set(key, defaultValues[key])
      })
    )
    if (withLoading) this.set(STORE_KEYS.LOADING, false)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
