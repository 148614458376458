import { useStore } from '@hooks/useStore'
import { Close } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme
} from '@mui/material'
import { IUiModalConfirm, IUiModalGeneric } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'

export const OvModal = observer(() => {
  const { ui } = useStore()
  const theme = useTheme()

  const handleClose = useCallback(() => {
    ui.closeModalGracefully()
  }, [ui.modal])

  const renderDialog = useMemo(() => {
    const { body, title } = ui.modal as IUiModalGeneric
    return (
      <Dialog
        fullWidth
        {...ui.modal?.modalOptions}
        maxWidth={'xl'}
        open={ui.modalOpen}
        onClose={handleClose}>
        <Card>
          <CardHeader
            title={title}
            action={
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            }
          />
          <CardContent>{body && body(ui.modal as IUiModalGeneric)}</CardContent>
        </Card>
      </Dialog>
    )
  }, [ui.modal, ui.modalOpen])

  const renderConfirm = useMemo(() => {
    const {
      title,
      content,
      confirmLabel,
      withCancel,
      cancelLabel,
      resolve,
      reject,
    } = ui.modal as IUiModalConfirm

    return (
      <Dialog
        {...ui.modal.modalOptions}
        open={ui.modalOpen}
        onClose={handleClose}>
        <DialogTitle variant="h3">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: content,
            }}></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: `${theme.spacing(2.5)}` }}>
          {withCancel && (
            <Button
              variant="outlined"
              onClick={handleClose}>
              {cancelLabel ?? 'Annuleren'}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={resolve}>
            {confirmLabel ?? 'Akkoord'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [ui.modal, ui.modalOpen])

  return ui.modal.type === 'confirm' ? renderConfirm : renderDialog
})
